:root {
  --primary: #9b5e9a;
  --primaryLight: #a56ea4;
  --primaryLighter: #c891c8;
  --primaryDark: #7c4b7b;
  --secondary: #4b3f4f;
  --secondaryLight: #5d5261;
  --secondaryDark: #3c323f;
  --success: #208920;
  --successLight: #4da14d;
  --successDark: #1a6e1a;
  --green: #00875f;
  --greenLight: #19936f;
  --greenDark: #006c4c;
  --yellow: #ffc845;
  --yellowLight: #ffd36a;
  --yellowDark: #e6b43e;
  --orange: #ffa33a;
  --orangeLight: #ffac4e;
  --orangeDark: #cc822e;
  --warning: #b56000;
  --warningLight: #bc7019;
  --warningDark: #a35600;
  --red: #ff4040;
  --redLight: #ff5353;
  --redDark: #e63a3a;
  --danger: #e52818;
  --dangerLight: #e83d2f;
  --dangerDark: #b72013;
  --info: #3475d6;
  --infoLight: #5d91de;
  --infoDark: #2a5eab;
  --black: #000000;
  --darkMore: #1a0d1e;
  --dark: #2d1234;
  --theme: #2b2739;
  --holderDark: #64656b;
  --muted: #6c757d;
  --themeGray: #9e9698;
  --lightgrey: #9b9ca1;
  --gray: #8c98a4;
  --smoke: #d3d4d9;
  --light: #f1e5f4;
  --silver: #f8f6f8;
  --lessLight: #eeecf1;
  --themeLight: #fdf1f6;
  --themeSilver: #f7f3f7;
  --lightMore: #f2edf4;
  --whitesmoke: #f5f5f5;
  --ghostLight: #e1e1e1;
  --ghostwhite: #fafafa;
  --white: #ffffff;
  --lightBorder: #e7eaf3;
  --holderLight: #cac9cd;

  --indigo: #2d1582;
  --purple: #6f42c1;
  --pink: #e83e8c;

  --themeDarkOntw: #131417;
  --themeDarkOn: #0c0c0e;
  --themeDarkTw: #151618;
  --themeDarkTh: #1e1f22;
  --themeDarkFo: #28292d;
  --themeDarkFi: #2f3034;
  --themeDarkSi: #60626c;
  --themeLightOn: #525459;
  --themeLightTw: #64656c;
  --themeLightTh: #75777f;
  --themeLightFo: #a9acb7;
  --themeLightFi: #ededf0;
  --themeLightSi: #fefefe;

  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;

  --bs-body-color-rgb: 33, 37, 41;
  --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);

  --fontWeight100: 100;
  --fontWeight200: 200;
  --fontWeight300: 300;
  --fontWeight400: 400;
  --fontWeight500: 500;
  --fontWeight600: 600;
  --fontWeight700: 700;
  --fontWeight800: 800;
  --fontWeight900: 900;

  --font-nunito: 'Nunito Sans', sans-serif;
}
/* reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: inherit;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
*,
.ant-layout {
  font-weight: var(--fontWeight600);
  font-family: var(--font-nunito) !important;
}
.light-theme body {
  background-color: #f4f2f9;
}
.dark-theme body {
  background-color: var(--themeDarkTh);
}
.light-theme .ant-layout.ant-layout-has-sider {
  background-color: var(--white);
}
.dark-theme .ant-layout.ant-layout-has-sider {
  background-color: var(--themeDarkTw);
}
body {
  font-family: var(--font-nunito) !important;
  overflow-x: hidden;
  line-height: 1;
  min-height: 100vh;
}
hr {
  border: 0;
  height: 1px;
  margin: 1.5rem 0;
}
a {
  color: var(--primary);
}
a:hover {
  color: var(--primaryLight);
}
p {
  font-size: 15px;
  line-height: 1.5;
  font-weight: var(--fontWeight500);
}
h1,
.h1 {
  font-size: 30px !important;
  font-weight: var(--fontWeight600);
}
h2,
.h2 {
  font-size: 26px !important;
  font-weight: var(--fontWeight600);
}
h3,
.h3 {
  font-size: 22px !important;
  font-weight: var(--fontWeight600);
}
h4,
.h4 {
  font-size: 18px !important;
  font-weight: var(--fontWeight600);
}
h5,
.h5 {
  font-size: 16px !important;
  font-weight: var(--fontWeight600);
}
sup {
  top: -0.7em;
}
sub,
sup {
  position: relative;
  font-size: medium;
  line-height: 0;
  vertical-align: baseline;
}
#root {
  min-height: 100vh;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
small {
  font-size: 88%;
  font-weight: var(--fontWeight400);
  line-height: 1.25;
}
/* /reset */
/* Nunito Sans */
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-200.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-200.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-200.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-200.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-200.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-200.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-200italic.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-200italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-200italic.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-200italic.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-200italic.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-200italic.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-300.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-300.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-300.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-300.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-300.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-300italic.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-300italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-300italic.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-300italic.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-300italic.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-300italic.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-regular.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-regular.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-regular.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-regular.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-regular.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-italic.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-italic.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-italic.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-italic.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-italic.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-600.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-600.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-600.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-600.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-600.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-600.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-600italic.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-600italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-600italic.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-600italic.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-600italic.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-600italic.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-700.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-700.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-700.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-700.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-700.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-700italic.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-700italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-700italic.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-700italic.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-700italic.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-700italic.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-800.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-800.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-800.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-800.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-800.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-800.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-800italic.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-800italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-800italic.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-800italic.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-800italic.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-800italic.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-900.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-900.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-900.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-900.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-900.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-900.svg#NunitoSans') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/nunitosans/nunito-sans-v12-latin-900italic.eot');
  src:
    url('../fonts/nunitosans/nunito-sans-v12-latin-900italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-900italic.woff2') format('woff2'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-900italic.woff') format('woff'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-900italic.ttf') format('truetype'),
    url('../fonts/nunitosans/nunito-sans-v12-latin-900italic.svg#NunitoSans') format('svg');
}
/* /Nunito Sans */
/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-corner {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(113, 107, 115, 0.6);
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(113, 107, 115, 0.7);
}
body *::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.light-theme body *::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
.light-theme body *::-webkit-scrollbar-thumb:hover {
  background: rgba(79, 00, 0, 0.25);
}
.dark-theme body *::-webkit-scrollbar-thumb {
  background: rgba(206, 200, 207, 0.25);
}
.dark-theme body *::-webkit-scrollbar-thumb:hover {
  background: rgba(206, 200, 207, 0.3);
}
.slimScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
@-moz-document url-prefix() {
  /* Firefox only */
  * {
    scrollbar-color: rgba(113, 107, 115, 0.2) rgba(113, 107, 115, 0.2);
    scrollbar-width: thin;
  }
}
.rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
  border-radius: 0 !important;
  cursor: default !important;
  width: 7px !important;
}
.light-theme .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
}
.light-theme .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb:hover {
  background: rgba(79, 00, 0, 0.25) !important;
}
.dark-theme .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
  background: rgba(206, 200, 207, 0.25) !important;
}
.dark-theme .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb:hover {
  background: rgba(206, 200, 207, 0.3) !important;
}
/* /scrollbar */
/* selection */
.unselectable {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* overflow */
.overflowAuto {
  overflow: auto !important;
}
.overflowHidden {
  overflow: hidden !important;
}
.wordBreak {
  word-break: break-word !important;
}
.wordBreakAll {
  word-break: break-all !important;
}
.wordBreakUnset {
  word-break: unset !important;
}
.wordBreakInitial {
  word-break: initial !important;
}
.whiteSpaceNowrap {
  white-space: nowrap !important;
}
/* /overflow */
/* pointer */
.cursorPointer {
  cursor: pointer !important;
}
.cursorDefault {
  cursor: default !important;
}
.positionRelative {
  position: relative !important;
}
.positionAbsolute {
  position: absolute !important;
}
.positionUnset {
  position: unset !important;
}
/* /pointer */
/* zIndex */
.zIndex1 {
  z-index: 1 !important;
}
.zIndex2 {
  z-index: 2 !important;
}
.zIndex3 {
  z-index: 3 !important;
}
.zIndex4 {
  z-index: 4 !important;
}
.zIndex5 {
  z-index: 5 !important;
}
.zIndex9 {
  z-index: 9 !important;
}
.zIndex99 {
  z-index: 99 !important;
}
/* /zIndex1 */
/* vertical align */
.verticalTop {
  vertical-align: top !important;
}
.verticalMiddle {
  vertical-align: middle !important;
}
.verticalBottom {
  vertical-align: bottom !important;
}
/* /vertical align */
/* line height */
.lineHeightNormal {
  line-height: normal !important;
}
.lineHeightInitial {
  line-height: initial !important;
}
.lineHeightInherit {
  line-height: inherit !important;
}
.lineHeight1 {
  line-height: 1 !important;
}
.lineHeight1-1 {
  line-height: 1.1 !important;
}
.lineHeight1-2 {
  line-height: 1.2 !important;
}
.lineHeight1-4 {
  line-height: 1.3 !important;
}
.lineHeight1-4 {
  line-height: 1.4 !important;
}
.lineHeight1-5 {
  line-height: 1.5 !important;
}
/* /line height */
/* position */
.top0 {
  top: 0px !important;
}
.top1 {
  top: 1px !important;
}
.top2 {
  top: 2px !important;
}
.top3 {
  top: 3px !important;
}
.top4 {
  top: 4px !important;
}
.top5 {
  top: 5px !important;
}
.top6 {
  top: 6px !important;
}
.top7 {
  top: 7px !important;
}
.top8 {
  top: 8px !important;
}
.top9 {
  top: 9px !important;
}
.top10 {
  top: 10px !important;
}
.top11 {
  top: 11px !important;
}
.top12 {
  top: 12px !important;
}
.top15 {
  top: 15px !important;
}
.top20 {
  top: 20px !important;
}
.right0 {
  right: 0px !important;
}
.right5 {
  right: 5px !important;
}
.right6 {
  right: 6px !important;
}
.right7 {
  right: 7px !important;
}
.right8 {
  right: 8px !important;
}
.right10 {
  right: 10px !important;
}
.right15 {
  right: 15px !important;
}
.right20 {
  right: 20px !important;
}
.bottom0 {
  bottom: 0px !important;
}
.bottom5 {
  bottom: 5px !important;
}
.bottom6 {
  bottom: 6px !important;
}
.bottom7 {
  bottom: 7px !important;
}
.bottom8 {
  bottom: 8px !important;
}
.bottom10 {
  bottom: 10px !important;
}
.bottom15 {
  bottom: 15px !important;
}
.bottom20 {
  bottom: 20px !important;
}
.left0 {
  left: 0px !important;
}
.left5 {
  left: 5px !important;
}
.left10 {
  left: 10px !important;
}
.left15 {
  left: 15px !important;
}
.left20 {
  left: 20px !important;
}
.topM0 {
  top: -0px !important;
}
.topM1 {
  top: -1px !important;
}
.topM2 {
  top: -2px !important;
}
.topM3 {
  top: -3px !important;
}
.topM4 {
  top: -4px !important;
}
.topM5 {
  top: -5px !important;
}
.topM6 {
  top: -6px !important;
}
.topM7 {
  top: -7px !important;
}
.topM8 {
  top: -8px !important;
}
.topM9 {
  top: -9px !important;
}
.topM10 {
  top: -10px !important;
}
/* /position */
/* font size */
.smallCheck label,
.font12 {
  font-size: 12px !important;
}
.font13 {
  font-size: 13px !important;
}
/* /font size */
/* Text colors */
.textPrimary {
  color: var(--primary) !important;
}
.textPrimaryLight {
  color: var(--primaryLight) !important;
}
.textPrimaryDark {
  color: var(--primaryDark) !important;
}
.textSecondary {
  color: var(--secondary) !important;
}
.textSecondaryLight {
  color: var(--secondaryLight) !important;
}
.textSecondaryDark {
  color: var(--secondaryDark) !important;
}
.textSuccess {
  color: var(--success) !important;
}
.textSuccessLight {
  color: var(--successLight) !important;
}
.textSuccessDark {
  color: var(--successDark) !important;
}
.textInfo {
  color: var(--info) !important;
}
.textInfoLight {
  color: var(--infoLight) !important;
}
.textInfoDark {
  color: var(--infoDark) !important;
}
.textWarning {
  color: var(--warning) !important;
}
.textWarningLight {
  color: var(--warningLight) !important;
}
.textWarningDark {
  color: var(--warningDark) !important;
}
.textDanger {
  color: var(--danger) !important;
}
.textDangerLight {
  color: var(--dangerLight) !important;
}
.textDangerDark {
  color: var(--dangerDark) !important;
}
.textLight {
  color: var(--light) !important;
}
.textDark {
  color: var(--dark) !important;
}
.textBlack {
  color: var(--black) !important;
}
.textWhite {
  color: var(--white) !important;
}
.textMuted {
  color: var(--muted) !important;
}
.textGray {
  color: var(--gray) !important;
}
.textNone {
  text-decoration: none !important;
}
.textUnset {
  text-decoration: unset !important;
}
.textUnderline {
  text-decoration: underline !important;
}
.textLinethrough {
  text-decoration: line-through !important;
}
/* Border colors */
.border {
  border: 1px solid var(--theme);
}
.borderPrimary {
  border-color: var(--primary) !important;
}
.borderPrimaryLight {
  border-color: var(--primaryLight) !important;
}
.borderPrimaryDark {
  border-color: var(--primaryDark) !important;
}
.borderSecondary {
  border-color: var(--secondary) !important;
}
.borderSecondaryLight {
  border-color: var(--secondaryLight) !important;
}
.borderSecondaryDark {
  border-color: var(--secondaryDark) !important;
}
.borderSuccess {
  border-color: var(--success) !important;
}
.borderSuccessLight {
  border-color: var(--successLight) !important;
}
.borderSuccessDark {
  border-color: var(--successDark) !important;
}
.borderInfo {
  border-color: var(--info) !important;
}
.borderInfoLight {
  border-color: var(--infoLight) !important;
}
.borderInfoDark {
  border-color: var(--infoDark) !important;
}
.borderWarning {
  border-color: var(--warning) !important;
}
.borderWarningLight {
  border-color: var(--warningLight) !important;
}
.borderWarningDark {
  border-color: var(--warningDark) !important;
}
.borderDanger {
  border-color: var(--danger) !important;
}
.borderDangerLight {
  border-color: var(--dangerLight) !important;
}
.borderDangerDark {
  border-color: var(--dangerDark) !important;
}
.borderLight {
  border-color: var(--light) !important;
}
.borderDark {
  border-color: var(--dark) !important;
}
.borderBlack {
  border-color: var(--black) !important;
}
.borderWhite {
  border-color: var(--white) !important;
}
.borderMuted {
  border-color: var(--muted) !important;
}
/* /Text colors */
/* bg */
.ant-picker-panel,
.bgTransparent {
  background-color: transparent !important;
}
.bgPrimary {
  background-color: var(--primary) !important;
}
.bgPrimaryLight {
  background-color: var(--primaryLight) !important;
}
.bgPrimaryDark {
  background-color: var(--primaryDark) !important;
}
.bgSecondary {
  background-color: var(--secondary) !important;
}
.bgSecondaryLight {
  background-color: var(--secondaryLight) !important;
}
.bgSecondaryDark {
  background-color: var(--secondaryDark) !important;
}
.bgSuccess {
  background-color: var(--success) !important;
}
.bgSuccessLight {
  background-color: var(--successLight) !important;
}
.bgSuccessDark {
  background-color: var(--successDark) !important;
}
.bgInfo {
  background-color: var(--info) !important;
}
.bgInfoLight {
  background-color: var(--infoLight) !important;
}
.bgGray {
  background-color: var(--gray) !important;
}
.bgInfoDark {
  background-color: var(--infoDark) !important;
}
.bgWarning {
  background-color: var(--warning) !important;
}
.bgWarningLight {
  background-color: var(--warningLight) !important;
}
.bgWarningDark {
  background-color: var(--warningDark) !important;
}
.bgDanger {
  background-color: var(--danger) !important;
}
.bgDangerLight {
  background-color: var(--dangerLight) !important;
}
.bgDangerDark {
  background-color: var(--dangerDark) !important;
}
.bgLight {
  background-color: var(--light) !important;
}
.bgSilver {
  background-color: var(--silver) !important;
}
.bgDark {
  background-color: var(--dark) !important;
}
.bgBlack {
  background-color: var(--black) !important;
}
.bgWhite {
  background-color: var(--white) !important;
}
/* /bg */
/* shadow */
.d-contents {
  display: contents;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
/* /shadow */
/* listing */
.dark-theme .oeList,
.dark-theme .mafListCommon {
  border: rgba(255, 255, 255, 0.06);
  border-radius: 10px;
}
.oeList .oelItems,
.mafListCommon .mafItems {
  padding: 8px 12px;
}
.mafListCommon .mafItems:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.mafListCommon .mafItems:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.light-theme .oeList .oelItems:nth-child(odd),
.light-theme .mafListCommon .mafItems:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.light-theme .oeList .oelItems:nth-child(even),
.light-theme .mafListCommon .mafItems:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.02);
}
.dark-theme .oeList .oelItems:nth-child(odd),
.dark-theme .mafListCommon .mafItems:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.045);
}
.dark-theme .oeList .oelItems:nth-child(even),
.dark-theme .mafListCommon .mafItems:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.03);
}
.dark-theme .oeList .oelItems:not(:last-child),
.dark-theme .mafListCommon .mafItems:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.02);
}
.dark-theme .mafListCommon .mafItems .icnCheckboxCommon.uncheck {
  color: var(--themeLightFo);
}
.light-theme .daciFieldCommon:nth-child(odd) {
  background-color: rgba(255, 255, 255, 1);
}
.light-theme .daciFieldCommon:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .daciFieldCommon:nth-child(odd) {
  background-color: #28292c;
}
.dark-theme .daciFieldCommon:nth-child(even) {
  background-color: #252629;
}
/* /listing */
/* ant-badge */
.ant-badge .ant-badge-count {
  height: auto;
  font-size: 13px;
  box-shadow: none;
  line-height: initial;
  padding: 0.438rem 18px;
  border-radius: 8px !important;
}
/* /ant-badge */
/* avtar */
.avtar20 {
  flex: 0 0 30px;
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.avtar30 {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.avtar40 {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.avtar50 {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.avtar60 {
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.avtar70 {
  flex: 0 0 70px;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.avtar80 {
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.avtar90 {
  flex: 0 0 90px;
  width: 90px;
  height: 90px;
  object-fit: cover;
}
.avtar100 {
  flex: 0 0 100px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.objectFit {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* /avtar */
/* Radius */
.rounded {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}
.roundedCircle {
  border-radius: 50% !important;
}
.roundedPill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.roundedTop {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.roundedTop-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.roundedTop-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}
.roundedTop-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.roundedTop-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}
.roundedTop-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}
.roundedTop-5 {
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
}
.roundedTopCircle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.roundedTopZPill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}
.roundedEnd {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.roundedEnd-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.roundedEnd-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}
.roundedEnd-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.roundedEnd-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}
.roundedEnd-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}
.roundedEnd-5 {
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
}
.roundedEndCircle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.roundedEndPill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}
.roundedBottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.roundedBottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.roundedBottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}
.roundedBottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.roundedBottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}
.roundedBottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}
.roundedBottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
}
.roundedBottomCircle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.roundedBottomPill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}
.roundedStart {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.roundedStart-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.roundedStart-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}
.roundedStart-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.roundedStart-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}
.roundedStart-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}
.roundedStart-5 {
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
}
.roundedStartCircle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}
.roundedStartPill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}
/* radius */
/* opacity */
.opacity100 {
  opacity: 1 !important;
}
.opacity90 {
  opacity: 0.9 !important;
}
.opacity80 {
  opacity: 0.8 !important;
}
.opacity70 {
  opacity: 0.7 !important;
}
.opacity60 {
  opacity: 0.6 !important;
}
.opacity50 {
  opacity: 0.5 !important;
}
.opacity40 {
  opacity: 0.4 !important;
}
.opacity30 {
  opacity: 0.3 !important;
}
.opacity20 {
  opacity: 0.2 !important;
}
.opacity10 {
  opacity: 0.1 !important;
}
.opacity-0 {
  opacity: 0 !important;
}
/* /opacity */
/* margin-padding */
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
/* /margin-padding */
/* Gaps */
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}
.row-gap-0 {
  row-gap: 0 !important;
}
.row-gap-1 {
  row-gap: 0.25rem !important;
}
.row-gap-2 {
  row-gap: 0.5rem !important;
}
.row-gap-3 {
  row-gap: 1rem !important;
}
.row-gap-4 {
  row-gap: 1.5rem !important;
}
.row-gap-5 {
  row-gap: 3rem !important;
}
.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}
.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important;
}
.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important;
}
.column-gap-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}
.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}
.column-gap-5 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}
@media (min-width: 576px) {
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
}
/* /Gaps */
/* width and height */
.mx-200 {
  max-width: 200px !important;
}
.mx-250 {
  max-width: 250px !important;
}
.w-50px {
  width: 50px !important;
}
.w-60px {
  width: 60px !important;
}
.w-70px {
  width: 70px !important;
}
.w-80px {
  width: 80px !important;
}
.w-90px {
  width: 90px !important;
}
.w-100px {
  width: 100px !important;
}
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-5 {
  height: 5% !important;
}
.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-25 {
  height: 25% !important;
}
.h-30 {
  height: 30% !important;
}
.h-35 {
  height: 35% !important;
}
.h-40 {
  height: 40% !important;
}
.h-45 {
  height: 45% !important;
}
.h-50 {
  height: 50% !important;
}
.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-75 {
  height: 75% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  height: 90% !important;
}
.h-95 {
  height: 95% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.minh-auto {
  min-height: auto !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
/* /width and height */
/* Flex property  */
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.flex-auto {
  flex: 0 0 auto;
  width: auto;
}
.flex-none {
  flex: none;
}
.flex-1 {
  flex: 1;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .textSmStart {
    text-align: left !important;
  }
  .textSmEnd {
    text-align: right !important;
  }
  .textSmCenter {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .textMdStart {
    text-align: left !important;
  }
  .textMdEnd {
    text-align: right !important;
  }
  .textMdCenter {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .textLgStart {
    text-align: left !important;
  }
  .textLgEnd {
    text-align: right !important;
  }
  .textLgCenter {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .textXlStart {
    text-align: left !important;
  }
  .textXlEnd {
    text-align: right !important;
  }
  .textXlCenter {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .textXxlStart {
    text-align: left !important;
  }
  .textXxlEnd {
    text-align: right !important;
  }
  .textXxlCenter {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* /Flex property */
/* Table */
.bioDataTable table {
  width: 100% !important;
}
.createUUT table {
  table-layout: unset !important;
}
.tableMiddle th,
.tableMiddle td {
  vertical-align: middle !important;
}
.inviteesTable td .fgFilters {
  margin-bottom: 0;
}
.conditionsTable table thead.ant-table-thead>tr:nth-child(1)>th:nth-child(1) {
  border-right-width: 3px !important;
}
.conditionsTable table thead.ant-table-thead>tr:nth-child(2)>th:nth-child(4) {
  border-right-width: 3px !important;
}
table .tbRight,
.conditionsTable table tbody.ant-table-tbody>tr>td:nth-child(4) {
  border-right-width: 3px !important;
}
/* Browser specific CSS */
@-moz-document url-prefix() {
  /* Firefox only */
  /* .custTableCommon th,
  .custTableCommon td {
    width: auto !important;
    min-width: auto !important;
  } */
}
/* /Browser specific CSS */
.tSBTable {
  margin-top: -30px;
  margin-right: 35px;
}
.checkboxScrore .cSItems:not(:last-child) {
  margin-bottom: 10px;
}
.checkboxScrore .cSItems {
  padding-right: 5px;
}
.checkboxScrore .cSItems .formGrpCommon {
  margin-bottom: 0 !important;
}
.checkboxScrore .cSItems .formGrpCommon>label {
  position: relative;
  top: 3px;
}
.checkboxScrore .cSItems .formGrpCommon>input {
  max-width: 50px;
  text-align: center;
  padding: 3px;
}
.tSBFooter {
  display: inline-flex;
}
.light-theme .shiftPlansTable .ant-table tbody tr:hover td,
.light-theme .shiftPlansTable .ant-table tbody tr td {
  background: var(--white) !important;
}
.dark-theme .shiftPlansTable .ant-table tbody tr:hover,
.dark-theme .shiftPlansTable .ant-table tbody tr {
  background: transparent !important;
}
/* /Table */
/* text align */
.textStart {
  text-align: left !important;
}
.textEnd {
  text-align: right !important;
}
.textCenter {
  text-align: center !important;
}
.textLowercase {
  text-transform: lowercase !important;
}
.textUppercase {
  text-transform: uppercase !important;
}
.textCapitalize {
  text-transform: capitalize !important;
}
/* /text align */
/* font weight */
.fontWeight100 {
  font-weight: var(--fontWeight100) !important;
}
.fontWeight200 {
  font-weight: var(--fontWeight200) !important;
}
.fontWeight300 {
  font-weight: var(--fontWeight300) !important;
}
.fontWeight400 {
  font-weight: var(--fontWeight400) !important;
}
.fontWeight500 {
  font-weight: var(--fontWeight500) !important;
}
.fontWeight600 {
  font-weight: var(--fontWeight600) !important;
}
.fontWeight700 {
  font-weight: var(--fontWeight700) !important;
}
.fontWeight800 {
  font-weight: var(--fontWeight800) !important;
}
.fontWeight900 {
  font-weight: var(--fontWeight900) !important;
}
/* /font weight */
/* ant-btn */
.ant-btn {
  color: var(--darkMore) !important;
  height: auto;
  cursor: pointer;
  display: inline-block;
  padding: 0.6565rem 22px;
  font-size: 14px;
  text-align: center;
  font-family: var(--font-nunito);
  font-weight: var(--fontWeight500);
  line-height: 1.5;
  user-select: none;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  border-radius: 10px;
  vertical-align: middle;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.ant-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
  margin-inline-start: 5px;
}
.ant-btn-primary {
  color: var(--white) !important;
  background-color: var(--success) !important;
  box-shadow: 0 3px 6px rgba(0, 135, 95, 0.1) !important;
  -webkit-box-shadow: 0 3px 6px rgba(0, 135, 95, 0.1) !important;
}
.ant-btn-primary:not(:disabled):hover,
.ant-btn-primary:hover {
  color: var(--white) !important;
  transform: translateY(-3px) !important;
  background-color: var(--success) !important;
  box-shadow: 0 6px 12px rgba(0, 135, 95, 0.3) !important;
  -webkit-box-shadow: 0 6px 12px rgba(0, 135, 95, 0.3) !important;
  transform: translateY(-3px) !important;
  -webkit-transform: translateY(-3px) !important;
}
.ant-btn-primary:focus,
.ant-btn-primary.focus,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  box-shadow: 0 6px 12px rgba(0, 135, 95, 0.3) !important;
  -webkit-box-shadow: 0 6px 12px rgba(0, 135, 95, 0.3) !important;
}
.ant-btn-default {
  color: var(--white) !important;
  box-shadow: 0 3px 6px rgba(75, 63, 79, 0.1) !important;
  background-color: var(--secondary) !important;
  -webkit-box-shadow: 0 3px 6px rgba(75, 63, 79, 0.1) !important;
}
.ant-btn-default:not(:disabled):hover,
.ant-btn-default:hover {
  color: var(--white) !important;
  transform: translateY(-3px) !important;
  box-shadow: 0 6px 12px rgaba(75, 63, 79, 0.3) !important;
  background-color: var(--secondary) !important;
  -webkit-transform: translateY(-3px) !important;
  -webkit-box-shadow: 0 6px 12px rgaba(75, 63, 79, 0.3) !important;
}
.ant-btn-default:focus,
.ant-btn-default.focus,
.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active,
.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active {
  box-shadow: 0 6px 12px rgaba(75, 63, 79, 0.3) !important;
  -webkit-box-shadow: 0 6px 12px rgaba(75, 63, 79, 0.3) !important;
}
/* /ant-btn */
/* ant-rate */
.ant-rate {
  color: var(--yellow);
  font-size: 17px;
}
.ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 4px;
}
.ant-rate .ant-rate-star.ant-rate-star-zero .ant-rate-star-second {
  color: rgba(0, 0, 0, 0.12);
}
.dark-theme .ant-rate .ant-rate-star.ant-rate-star-zero .anticon-star svg path {
  fill: var(--white);
  opacity: 0.15;
}
/* /ant-rate */
/* animation */
/* border-transform */
@keyframes border-transform {

  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }

  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }

  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }

  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }

  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }

  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }

  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}
/* loader */
@keyframes animateHeyLC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes animateHeyL {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}
/* vrWiggle */
.vrWiggle:hover,
.vrWiggle:focus,
.vrWiggle:active {
  /* animation: vrWiggle 2s linear infinite; */
  animation: infinite;
  -webkit-animation-name: vrWiggle;
  animation-name: vrWiggle;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes vrWiggle {

  0%,
  7% {
    transform: rotateZ(0);
  }

  15% {
    transform: rotateZ(-15deg);
  }

  20% {
    transform: rotateZ(10deg);
  }

  25% {
    transform: rotateZ(-10deg);
  }

  30% {
    transform: rotateZ(6deg);
  }

  35% {
    transform: rotateZ(-4deg);
  }

  40%,
  100% {
    transform: rotateZ(0);
  }
}
/* vrWobbleHorizontal */
.vrWobbleHorizontal:hover,
.vrWobbleHorizontal:focus,
.vrWobbleHorizontal:active {
  /* animation: vrWobbleHorizontal 1s ease-in-out infinite; */
  -webkit-animation-name: vrWobbleHorizontal;
  animation-name: vrWobbleHorizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
@-webkit-keyframes vrWobbleHorizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes vrWobbleHorizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/* vrbuzzOut  */
.vrbuzzOut:hover,
.vrbuzzOut:focus,
.vrbuzzOut:active {
  /* animation: vrbuzzOut 0.75s linear infinite */
  -webkit-animation-name: vrbuzzOut;
  animation-name: vrbuzzOut;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
@-webkit-keyframes vrbuzzOut {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }

  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }

  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }

  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }

  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }

  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }

  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }

  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }

  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }

  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes vrbuzzOut {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }

  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }

  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }

  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }

  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }

  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }

  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }

  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }

  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }

  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
/* hvrWobble */
.hvrWobble {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}
.hvrWobble:hover,
.hvrWobble:focus,
.hvrWobble:active {
  /* animation: hvrWobble 1s ease-in-out infinite */
  -webkit-animation-name: hvrWobble;
  animation-name: hvrWobble;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
@-webkit-keyframes hvrWobble {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  33.3% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }

  49.95% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvrWobble {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  33.3% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }

  49.95% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/* hvrPop */
.hvrPop {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.hvrPop:hover,
.hvrPop:focus,
.hvrPop:active {
  /* animation: hvrPop 0.3s linear infinite */
  -webkit-animation-name: hvrPop;
  animation-name: hvrPop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
@-webkit-keyframes hvrPop {
  50% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
}
@keyframes hvrPop {
  50% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
}
/* hvrWobbleVertical */
.hvrWobbleVertical {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvrWobbleVertical:hover,
.hvrWobbleVertical:focus,
.hvrWobbleVertical:active {
  /* animation: hvrWobbleVertical 1s ease-in-out infinite */
  animation-name: hvrWobbleVertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes hvrWobbleVertical {
  16.65% {
    transform: translateY(8px);
  }

  33.3% {
    transform: translateY(-6px);
  }

  49.95% {
    transform: translateY(4px);
  }

  66.6% {
    transform: translateY(-2px);
  }

  83.25% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0);
  }
}
.animation {
  animation-duration: 1s;
  animation-fill-mode: both;
}
/* animationSlideInDown */
.animationSlideInDown {
  animation-name: animationSlideInDown;
}
@keyframes animationSlideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
/* animationSlideInUp */
.animationSlideInUp {
  animation-name: animationSlideInUp;
}
@keyframes animationSlideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
/* animationFadeIn */
.animationFadeIn {
  animation-name: animationFadeIn;
}
@keyframes animationFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
/* animationFadeOut */
.animationFadeOut {
  animation-name: animationFadeOut;
}
@keyframes animationFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
/* animationBlink */
.animationBlink {
  animation: animationBlink 1s steps(5, start) infinite;
}
@keyframes animationBlink {
  to {
    visibility: hidden;
  }
}
@keyframes svgFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
/* icnCheckbox */
@keyframes icnCheckbox {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
/* dotsPop */
@-webkit-keyframes dotsPop {
  50% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
}
@keyframes dotsPop {
  50% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
}
/* table border animation */
.tdBorder {
  position: relative;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
}
.tdBorder * {
  position: relative;
  z-index: 5;
}
.tdIBorder {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.tdIBorder span:nth-child(1) {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, transparent, var(--white));
  animation: animate1 2s linear infinite;
}
@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
.tdIBorder span:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to bottom, #17161800, var(--white));
  animation: animate2 2s linear infinite;
  animation-delay: 1s;
}
@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}
.tdIBorder span:nth-child(3) {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to left, #17161800, var(--white));
  animation: animate3 2s linear infinite;
}
@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
.tdIBorder span:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to top, #17161800, var(--white));
  animation: animate4 2s linear infinite;
  animation-delay: 1s;
}
@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}
/* /table border animation */
/* /animation */
@media (min-width: 992px) {
  .mainContent {
    min-height: calc(100vh - 102px);
  }
}
@media (max-width: 992px) {
  .mainContent {
    min-height: calc(100vh - 30px);
  }
}
.mainContent {
  padding: 10px 30px 20px;
}
.mainContent>main,
.mainContent>main>section {
  min-height: 100%;
}
.mainContent>main>section>.fullHeight {
  min-height: calc(100vh - 182px);
  height: 100%;
}
.fullScreen {
  padding: 25px;
  overflow: auto;
  max-height: 100vh;
}
/* form */
.selectMandatory select,
.textareaMandatory textarea,
.mandatory,
.checkboxMandatory .ant-checkbox .ant-checkbox-inner {
  border-color: var(--success) !important;
}
.checkboxMandatory .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--primary) !important;
}
select option {
  font-weight: var(--fontWeight600);
}
/* FireFox Hack CSS */
@-moz-document url-prefix() {

  *,
  select option {
    font-weight: var(--fontWeight500);
    font-family: var(--font-nunito);
  }
}
.light-theme select option {
  background-color: var(--white) !important;
  color: var(--theme) !important;
}
.dark-theme select option {
  background-color: var(--themeDarkFo) !important;
  color: var(--themeLightFo) !important;
}
.dark-theme .inputGIcnCommon {
  margin-left: 0 !important;
  border-inline-start: none !important;
}
.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

input:disabled+.inputGIcnCommon,
input[disabled],
input:disabled,
select[disabled],
select:disabled,
textarea[disabled],
textarea:disabled,
button[disabled],
button:disabled {
  opacity: 0.55;
  resize: none;
  cursor: not-allowed;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* unselect */
.disabled,
.unselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* /unselect */
label {
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 3px;
  display: inline-block;
  font-family: var(--font-nunito);
  font-weight: var(--fontWeight600);
}
textarea {
  line-height: 1.55 !important;
  resize: vertical;
}
.error-msg {
  color: var(--danger) !important;
  font-size: 13px;
}
/* placeholder */
.ant-select-selection-placeholder {
  font-weight: var(--fontWeight600);
  font-family: var(--font-nunito);
  font-size: 14px;
}
.ant-select-selection-placeholder {
  font-weight: var(--fontWeight600);
  font-family: var(--font-nunito);
  font-size: 14px;
}
.ant-select-selection-placeholder {
  font-weight: var(--fontWeight600);
  font-family: var(--font-nunito);
  font-size: 14px;
}
::-webkit-input-placeholder {
  font-weight: var(--fontWeight500);
  font-family: var(--font-nunito);
  font-size: 14px;
}
:-ms-input-placeholder {
  font-weight: var(--fontWeight500);
  font-family: var(--font-nunito);
  font-size: 14px;
}
::placeholder {
  font-weight: var(--fontWeight500);
  font-family: var(--font-nunito);
  font-size: 14px;
}
/* ant-select */
.ant-space,
.ant-select {
  width: 100% !important;
  border: 0 !important;
  padding: 0 !important;
}
.ant-select-disabled {
  opacity: 0.55;
}
/* /ant-select */
/* ant-picker */
.ant-picker .ant-picker-input .ant-picker-suffix .anticon svg {
  width: 18px;
  height: 18px;
}
.ant-picker-footer .ant-picker-ok button {
  background-color: var(--primary) !important;
  color: var(--white);
}
.ant-picker-footer .ant-picker-ok button:hover {
  background-color: var(--primary) !important;
  box-shadow: 0 6px 12px rgba(155, 94, 154, 0.3) !important;
  -webkit-box-shadow: 0 6px 12px rgba(155, 94, 154, 0.3) !important;
}
.ant-picker-month-panel .ant-picker-cell .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell .ant-picker-cell-inner,
.ant-picker-decade-panel .ant-picker-cell .ant-picker-cell-inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: var(--fontWeight600);
}
.ant-picker-cell.ant-picker-cell-in-view:hover .ant-picker-cell-inner {
  background: var(--primary) !important;
  color: var(--white) !important;
  margin: -2px;
}
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected:hover .ant-picker-cell-inner {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}
.ant-picker-date-panel .ant-picker-cell .ant-picker-cell-inner {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50rem;
}
.ant-picker-date-panel .ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--primary) !important;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50rem !important;
}
/* /ant-picker */
/* radio */
.ant-radio-wrapper {
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.ant-radio-wrapper .ant-radio .ant-radio-inner {
  top: -1px;
  width: 20px;
  border-style: solid;
  border-width: 1px;
  height: 20px;
  background-color: rgba(215, 207, 216, 0.2);
}
.ant-radio-wrapper:hover .ant-radio .ant-radio-inner {
  border-color: var(--primary);
}
.ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary);
  background-color: var(--primary);
}
.ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--white);
}
.ant-radio-wrapper .ant-radio.ant-radio-checked.ant-radio-disabled .ant-radio-inner+span,
.ant-radio-wrapper .ant-radio.ant-radio-checked.ant-radio-disabled .ant-radio-inner {
  opacity: 0.55;
}
/* /radio */
/* ant-checkbox */
.ant-checkbox-wrapper .ant-checkbox {
  position: relative;
  top: 0px;
}
.isPostCode .ant-checkbox,
.ant-table-filter-dropdown .ant-checkbox-wrapper .ant-checkbox,
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .ant-checkbox-wrapper .ant-checkbox {
  top: -2px;
}
.ant-checkbox input {
  min-height: 0;
}
.ant-checkbox .ant-checkbox-inner {
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.ant-checkbox-checked:after {
  top: 0;
  inset-inline-start: 0;
  border-radius: 5px;
  border: 1px solid var(--primary);
}
.light-theme .ant-checkbox .ant-checkbox-inner {
  border-color: #bfbfbf;
}
.ant-checkbox .ant-checkbox-inner:after {
  inset-inline-start: 28%;
}
.ant-checkbox-wrapper {
  display: inline-flex;
  align-items: center;
}
.ant-checkbox-wrapper .ant-checkbox+span {
  padding-inline-start: 0;
  padding-inline-end: 0;
}
.ant-checkbox-wrapper .ant-checkbox+span>span {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  display: inline-flex;
  line-height: initial;
}
.isPostCode .ant-checkbox-wrapper .ant-checkbox+span>span {
  padding-inline-end: 0;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #d7cfd8;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: var(--primary);
}
.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: var(--primary);
}
.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: var(--primary);
}
.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: rgba(255, 255, 255, 0.25);
}
.ant-checkbox-disabled+span {
  color: rgba(0, 0, 0, 0.65);
}
.dark-theme .ant-checkbox .ant-checkbox-inner {
  border-color: rgba(215, 207, 216, 0.2);
  background-color: transparent;
}
.dark-theme .ant-checkbox .ant-checkbox-inner {
  border-color: rgba(215, 207, 216, 0.2);
  background-color: transparent;
}
.ant-checkbox .ant-checkbox-input {
  min-height: 20px;
}
/* /ant-checkbox */
/* select-selector */
.ant-picker {
  color: var(--darkMore);
  width: 100%;
  border: 0.0625rem solid var(--lightBorder) !important;
  display: block;
  padding: 0.3rem 14px !important;
  font-size: 0.875rem;
  min-height: calc(0.972em + 1.65rem + 2px);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-family: var(--font-nunito);
  font-weight: var(--fontWeight600);
  line-height: 1.5;
  border-radius: 10px !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-clip: padding-box;
  background-color: var(--white);
}
.ant-picker-focused {
  color: var(--white);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25) !important;
  border-color: rgba(140, 152, 164, 0.25);
  background-color: var(--white);
}
.ant-picker-cell-today .ant-picker-cell-inner {
  color: var(--primary);
}
.ant-picker-header-view button:hover {
  color: var(--primary) !important;
}
.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--primary) !important;
}
/* /select-selector */
/* select-search */
.ant-select-dropdown .ant-select-item-option-content {
  white-space: unset !important;
}
.ssScroll .ant-select-selector {
  overflow: auto;
  max-height: 10rem;
}
/* /select-search */
/* ellipsis */
.ellipsisL1 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsisL2 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsisL3 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsisL4 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsisParent {
  overflow: hidden;
  max-width: calc(100% - 1px);
  text-overflow: ellipsis;
}
.ellipsisChild {
  /* max-width: 180px; */
  overflow: hidden;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.ellipsisP {
  overflow: hidden;
  max-width: calc(100% - 1px);
  text-overflow: ellipsis;
}
.ellipsisC {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* /ellipsis */
/* ant-switch */
.ant-switch {
  height: 27px;
  line-height: 26px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.25);
}
.ant-switch.ant-switch-checked {
  background: var(--primary);
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: var(--primary);
}
.ant-switch .ant-switch-handle::before {
  border-radius: 5px;
}
.ant-switch .ant-switch-handle {
  width: 12px;
  top: 4px;
  inset-inline-start: 4px;
}
.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
  margin-top: -26px;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 17px);
}
.ant-switch:hover .ant-switch-handle {
  animation: vrWiggle 2s linear infinite;
}
/* ant-popover */
.ant-popover,
.ant-image-preview-root .ant-image-preview-wrap,
.ant-image-preview-root .ant-image-preview-mask,
.ant-image-preview-operations-wrapper {
  z-index: 99999 !important;
}
.ant-btn.ant-btn-sm {
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 24px;
  padding: 0px 7px;
  border-radius: 4px;
}
/* /ant-popover */
/* /ant-switch */
/* myRow */
.myRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.myRow>.ant-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.myCol-5 .ant-col {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 992px) {
  .referenceNum.myCol-5 .ant-col {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  .referenceNum.myCol-5 .ant-col {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 1439px) {
  .referenceNum.myCol-5 .ant-col {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/* /myRow */
/* autocomplete-dropdown */
.pcDropMenu {
  margin-top: 5px;
  padding: 6px;
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 12px;
  min-width: 100%;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1rem 3rem;
}
.pcDropMenu .pcdItems {
  padding: 8px 12px;
  margin-bottom: 2px;
  display: flex;
  border-radius: 8px;
  cursor: context-menu;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcDropMenu .pcdItems:hover .pcdIcn>svg>path {
  animation: vrWiggle 2s linear infinite;
}
.pcDropMenu .pcdItems .pcdIcn {
  width: 30px;
}
.pcDropMenu .pcdItems .pcdName {
  width: calc(100% - 30px);
  display: inline-block;
  line-height: 1.3;
}
.light-theme .teammUsers .userCCommon,
.light-theme .pcDropMenu {
  background-color: var(--white) !important;
}
.light-theme .pcDropMenu .pcdItems .pcdIcn>svg>path {
  fill: var(--theme);
}
.light-theme .pcDropMenu .pcdItems:hover {
  background-color: var(--whitesmoke);
}
.dark-theme .pcDropMenu {
  background-color: var(--themeDarkFo);
}
.dark-theme .pcDropMenu .pcdItems .pcdIcn>svg>path {
  fill: var(--themeLightFo);
}
.dark-theme .pcDropMenu .pcdItems .pcdName {
  color: var(--themeLightFo);
}
.dark-theme .pcDropMenu .pcdItems:hover {
  background-color: var(--themeDarkTh);
}
/* /autocomplete-dropdown */
/* modal */
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.65);
}
.ant-modal-root .ant-modal-mask,
.ant-modal-root .ant-modal-wrap {
  z-index: 10000 !important;
}
.ant-picker-dropdown .ant-picker-cell {
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-picker-dropdown,
.ant-select-dropdown {
  z-index: 10009 !important;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: var(--primary);
}
.modalStatic {
  animation: slide-in 0.5s ease forwards;
  /* Animation name, duration, timing function, fill mode */
}
.addOptionItems .ant-space-item:nth-child(1),
.addOptionItems .ant-space-item:nth-child(2),
.addRowItems .ant-space-item:first-child,
.addColItems .ant-space-item:first-child {
  flex: 1;
}
/* /modal */
/* date range picker */
.dark-theme .ant-picker-dropdown .ant-picker-range-arrow:before,
.dark-theme .ant-picker-dropdown .ant-picker-range-arrow:after {
  background: var(--themeDarkFi);
}
.light-theme .ant-picker-dropdown .ant-picker-range-arrow:before,
.light-theme .ant-picker-dropdown .ant-picker-range-arrow:after {
  background: var(--white);
}
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view:after {
  border-top-color: var(--primary) !important;
  border-bottom-color: var(--primary) !important;
}
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after {
  border-inline-end-color: var(--primary) !important;
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:after,
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
  border-inline-start-color: var(--primary) !important;
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start>.ant-picker-cell-inner,
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end>.ant-picker-cell-inner {
  border-radius: 50rem;
  background: var(--primary) !important;
}
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start:before,
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end:before,
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: var(--primary) !important;
  opacity: 0.2;
}
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end>.ant-picker-cell-inner:after,
.ant-picker-date-range-wrapper .ant-picker-panels .ant-picker-date-panel .ant-picker-content tbody tr td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start>.ant-picker-cell-inner:after {
  display: none !important;
}
/* /date range picker */
/* upload */
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select .ant-upload>img {
  border-radius: 8px;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  background-color: rgba(125, 75, 138, 0.06);
  overflow: hidden;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: var(--primary);
}
/* /upload */
.ant-tabs-dropdown {
  z-index: 99999;
}
.ant-dropdown {
  z-index: 99999 !important;
}
.ant-dropdown-placement-bottomRight .actionDropC:before {
  right: 14px;
}
.ant-dropdown-placement-bottomLeft .actionDropC:before {
  left: 13px;
}
.orgCreatePathwayTable table tr td .fgmb {
  margin-bottom: 0 !important;
}
/* override css */
.ant-select-tree,
.ant-menu-submenu-popup.ant-menu {
  background-color: transparent !important;
}
.createFormModal .cstRCommon {
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}
/* /override css */
/* UL OL */
.numList>li {
  list-style: decimal;
}
.numList>li:not(:last-child) {
  margin-bottom: 0.35rem;
}
/* /UL OL */
/* ant select tree css */
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode {
  width: 100%;
}
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open {
  padding-left: 10px;
  padding-right: 10px;
}
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open>.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal {
  padding: 0;
}
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open>.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal>.ant-select-tree-title {
  padding: 5px 25px 5px 12px;
  display: block;
  border-radius: 6px;
  font-weight: var(--fontWeight500);
  position: relative;
}
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open.ant-select-tree-treenode-selected>.ant-select-tree-node-content-wrapper.ant-select-tree-node-selected>.ant-select-tree-title:after {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  right: 10px;
  width: 7px;
  height: 12px;
  border: solid var(--primary);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-disabled {
  padding: 0;
}
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper {
  padding: 0;
}
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper>.ant-select-tree-title {
  padding: 5px 12px !important;
  display: block;
  border-radius: 6px;
  cursor: default;
  background-color: transparent !important;
  font-weight: var(--fontWeight600) !important;
}
/* light */
.light-theme .ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open>.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal>.ant-select-tree-title {
  color: var(--theme);
}
.light-theme .ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper>.ant-select-tree-title {
  color: var(--theme);
}
.light-theme .ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open>.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal>.ant-select-tree-title:hover {
  background-color: var(--whitesmoke);
  opacity: 1;
}
.light-theme .ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open.ant-select-tree-treenode-selected>.ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: var(--whitesmoke) !important;
  opacity: 1;
}
/* dark */
.dark-theme .ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open>.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal>.ant-select-tree-title {
  opacity: 0.7;
  color: var(--white);
}
.dark-theme .ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper>.ant-select-tree-title {
  opacity: 1 !important;
  color: var(--white);
}
.dark-theme .ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open>.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal>.ant-select-tree-title:hover {
  background-color: var(--themeDarkTh);
}
.dark-theme .ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open.ant-select-tree-treenode-selected>.ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: var(--themeDarkTh) !important;
}
/* /ant select tree css */
/* ant-menu */
.ant-menu-submenu-popup .ant-menu-sub>.ant-menu-submenu>.ant-menu-submenu-title>.ant-menu-title-content {
  margin-inline-start: 0;
}
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-item {
  height: 34px;
  line-height: 34px;
}
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  margin: 0;
}
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu {
  margin: 4px;
}
.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-submenu-title {
  padding-inline-end: 16px;
}
.ant-menu-submenu>.ant-menu .ant-menu-submenu-arrow {
  inset-inline-end: 6px;
}
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu.ant-menu-submenu-open.ant-menu-submenu-active .ant-menu-submenu-title .ant-menu-title-content,
.ant-menu-submenu-popup .ant-menu-item-selected,
.ant-menu-submenu-popup .ant-menu-item-active {
  color: var(--primary) !important;
}
.ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-popup .ant-menu-sub>li>.ant-menu-title-content {
  outline: none !important;
  outline-offset: unset !important;
  transition: unset !important;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
  padding: 10px 18px;
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-link {
  font-size: 13px;
  font-weight: var(--fontWeight600);
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-link:not(:disabled):hover {
  color: var(--primary);
}
.ant-table-filter-dropdown {
  border-radius: 8px;
  overflow: hidden;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 8px 10px;
  margin-bottom: 2px;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  border-radius: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  display: inline-flex;
  align-items: center;
}
.dark-theme .ant-table-wrapper .ant-table {
  background: rgba(255, 255, 255, 0.05);
}
/* ant-slider */
.ant-slider.ant-slider-disabled .ant-slider-rail {
  background-color: var(--muted);
  opacity: 0.2;
}
.ant-slider:hover .ant-slider-rail,
.ant-slider .ant-slider-rail {
  background-color: var(--success);
  opacity: 0.3;
}
.ant-slider.ant-slider-disabled .ant-slider-track,
.ant-slider:hover .ant-slider-track,
.ant-slider .ant-slider-track {
  background-color: var(--success) !important;
}
.ant-slider:hover .ant-slider-dot,
.ant-slider .ant-slider-dot,
.ant-slider:hover .ant-slider-dot-active,
.ant-slider .ant-slider-dot-active {
  border-color: var(--success);
}
.ant-slider:hover .ant-slider-handle::after,
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px var(--success);
}
.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px var(--success);
}
.ant-tooltip {
  z-index: 99999 !important;
  pointer-events: none !important;
}
.ant-tooltip .ant-tooltip-inner {
  line-height: 1.4;
  padding: 12px 14px;
  font-weight: 500;
}
.ant-tooltip.ant-slider-tooltip .ant-tooltip-inner {
  text-align: center;
}
.svg-inline--fa {
  vertical-align: middle;
}
.ant-picker-cell-disabled::before {
  height: 90%;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item>svg {
  width: 26px;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover>svg {
  animation: vrWiggle 2s linear infinite;
}
.ant-picker-panel>.ant-picker-footer>.ant-picker-today-btn,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover .ant-dropdown-menu-title-content {
  color: var(--primary);
}
.ant-slider.ant-slider-with-marks {
  margin-inline: 12px !important;
}
svg {
  vertical-align: middle;
}
.svgInfo,
.svgInfo>path {
  fill: var(--info) !important;
}
.svgWhite>path {
  fill: var(--white) !important;
}
.svgDanger>path {
  fill: var(--danger) !important;
}
.svgPrimary>path {
  fill: var(--primary) !important;
}
.svgSuccess>path {
  fill: var(--success) !important;
}
.svgWarning>path {
  fill: var(--warning) !important;
}
.ant-picker-dropdown .ant-picker-time-panel-column>li {
  margin-bottom: 2px;
}
.dark-theme .ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  background: rgba(0, 0, 0, 0.4) !important;
  opacity: 0.25;
}
.dark-theme .ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: rgba(0, 0, 0, 0.104);
}
/* /ant-slider */
/* sectionProgressBar */
.sectionProgressBar .ant-slider {
  padding-block: 0;
  height: 18px;
  margin: 0;
}
.sectionProgressBar .ant-slider .ant-slider-rail {
  background-color: rgb(32 136 32 / 15%) !important;
  opacity: 1 !important;
}
.sectionProgressBar .ant-slider .ant-slider-rail,
.sectionProgressBar .ant-slider .ant-slider-track,
.sectionProgressBar .ant-slider .ant-slider-step {
  height: 18px;
  cursor: auto;
  border-radius: 50rem;
}
.sectionProgressBar .ant-slider .ant-slider-handle {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
/* /sectionProgressBar */
/* tox */
.tox.tox-silver-sink {
  z-index: 999999;
}
.tox .tox-mbtn {
  display: inline-flex;
  align-items: center;
  cursor: pointer !important;
}
.tox .tox-mbtn__select-label {
  cursor: pointer !important;
}
.tox .tox-collection__item {
  margin-bottom: 1px;
}
.tox .tox-tbtn:hover,
.tox .tox-tbtn:focus,
.tox .tox-tbtn:active,
.tox .tox-mbtn--active,
.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover,
.tox .tox-mbtn:focus:not(:disabled),
.tox .tox-collection--list .tox-collection__item--enabled,
.tox .tox-collection--toolbar .tox-collection__item--active,
.tox .tox-collection--toolbar .tox-collection__item--enabled,
.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
  background: var(--primary) !important;
  color: var(--white) !important;
}
.tox .tox-mbtn--active>.tox-mbtn__select-label {
  color: var(--white) !important;
}
.tox .tox-mbtn:focus:not(:disabled)::after {
  display: none;
}
.tox .tox-tbtn:hover .tox-mbtn__select-label,
.tox .tox-tbtn--enabled:hover .tox-mbtn__select-label,
.tox .tox-collection--list .tox-collection__item--enabled .tox-collection__item-label p,
.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) .tox-mbtn__select-label {
  color: var(--white);
}
.tox .tox-split-button:focus {
  background: transparent !important;
}
.tox .tox-split-button:hover {
  box-shadow: none !important;
}
.tox .tox-tbtn:disabled,
.tox .tox-tbtn--disabled,
.tox .tox-tbtn--disabled:hover,
.tox .tox-tbtn:disabled:hover {
  background: transparent !important;
  opacity: 0.55;
}
.tox.tox-tinymce--disabled {
  opacity: 0.55;
}
.tox.tox-tinymce--disabled .tox-mbtn,
.tox.tox-tinymce--disabled .tox-mbtn__select-label,
.tox.tox-tinymce--disabled iframe {
  cursor: not-allowed !important;
}
.tox .tox-statusbar a {
  color: var(--themeLightFi) !important;
}
.tox .tox-collection--list .tox-collection__item--active {
  background-color: var(--primary) !important;
}
.tox .tox-collection__item-accessory {
  opacity: 0.45;
}
.home-layout .tox-dialog__header {
  padding: 15px !important;
}
.home-layout .tox .tox-dialog__title {
  font-size: 18px;
  font-weight: var(--fontWeight600);
  line-height: 1.5;
  word-wrap: break-word;
}
.home-layout .tox .tox-listbox__select-chevron svg>path,
.home-layout .tox .tox-dialog__header .tox-button .tox-icon svg>path {
  fill: #9d9d9d !important;
}
.home-layout .tox .tox-label,
.home-layout .tox .tox-toolbar-label {
  font-size: 15px;
  font-weight: var(--fontWeight600);
  line-height: 1.2;
  margin-bottom: 3px;
}
.home-layout .tox .tox-listboxfield .tox-listbox--select,
.home-layout .tox .tox-textarea,
.home-layout .tox .tox-textarea-wrap .tox-textarea:focus,
.home-layout .tox .tox-textfield,
.home-layout .tox .tox-toolbar-textfield {
  width: 100%;
  display: inline-flex;
  padding: 0.45rem 14px;
  font-size: 0.875rem;
  min-height: calc(0.972em + 1.65rem + 2px);
  transition:
    border-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  font-family: var(--font-nunito);
  font-weight: var(--fontWeight600);
  line-height: 1.8;
  border-radius: 10px;
  background-clip: padding-box;
  appearance: none;
}
.tox .tox-custom-editor:focus-within, 
.tox .tox-listboxfield .tox-listbox--select:focus, 
.tox .tox-textarea-wrap:focus-within, 
.tox .tox-textarea:focus, 
.tox .tox-textfield:focus,
.home-layout .tox .tox-listboxfield .tox-listbox--select:focus,
.home-layout .tox .tox-textarea:focus,
.home-layout .tox .tox-textarea-wrap .tox-textarea:focus,
.home-layout .tox .tox-textfield:focus,
.home-layout .tox .tox-toolbar-textfield:focus {
  box-shadow: none !important;
  outline: none !important;
}
.home-layout .tox .tox-form__controls-h-stack .tox-button .tox-icon svg>path {
  fill: var(--primary);
}
.home-layout .tox .tox-dialog__footer {
  padding: 16px;
  border-top: 1px solid rgba(242, 237, 244, 0.07);
}
.tox .tox-dialog__footer-end .tox-button,
.home-layout .tox .tox-dialog__footer-end .tox-button[title='Close'],
.home-layout .tox .tox-button--secondary {
  color: var(--darkMore);
  cursor: pointer;
  display: inline-flex;
  padding: 0.657rem 22px;
  font-size: 14px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  align-items: center;
  font-family: var(--font-nunito);
  font-weight: var(--fontWeight500);
  line-height: 1.5;
  user-select: none;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  border-radius: 10px;
  vertical-align: middle;
  justify-content: center;
  background-color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  color: var(--white);
  box-shadow: 0 3px 6px rgba(75, 63, 79, 0.1);
  background-color: var(--secondary);
  -webkit-box-shadow: 0 3px 6px rgba(75, 63, 79, 0.1);
}
.tox .tox-dialog__footer-end .tox-button:hover,
.home-layout .tox .tox-dialog__footer-end .tox-button[title='Close']:hover,
.home-layout .tox .tox-button--secondary:hover {
  color: var(--white) !important;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgaba(75, 63, 79, 0.3);
  background-color: var(--secondary) !important;
  -webkit-transform: translateY(-3px);
  -webkit-box-shadow: 0 6px 12px rgaba(75, 63, 79, 0.3);
}
.home-layout .tox .tox-button[title='Save'] {
  color: var(--darkMore);
  cursor: pointer;
  display: inline-flex;
  padding: 0.657rem 22px;
  font-size: 14px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  align-items: center;
  font-family: var(--font-nunito);
  font-weight: var(--fontWeight500);
  line-height: 1.5;
  user-select: none;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  border-radius: 10px;
  vertical-align: middle;
  justify-content: center;
  background-color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  color: var(--white);
  box-shadow: 0 3px 6px rgba(32, 137, 31, 0.1);
  background-color: var(--success);
  -webkit-box-shadow: 0 3px 6px rgba(32, 137, 31, 0.1);
}
.home-layout .tox .tox-button[title='Save']:hover {
  color: var(--white);
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(32, 137, 31, 0.3);
  background-color: var(--success);
  -webkit-transform: translateY(-3px);
  -webkit-box-shadow: 0 6px 12px rgba(32, 137, 31, 0.3);
}
.home-layout .tox .tox-dialog-wrap__backdrop {
  background-color: rgba(0, 0, 0, 0.45);
}
.home-layout .tox .tox-dialog__body-nav-item {
  font-weight: 600;
}
.home-layout .tox .tox-dialog__body-nav-item--active {
  border-bottom-color: var(--primary);
  color: var(--primary) !important;
}
.home-layout .tox .tox-dialog__body-nav-item:focus {
  background: transparent !important;
}
.home-layout .tox .tox-collection__item.tox-collection__item--active {
  background: var(--primary) !important;
}
.home-layout .tox .tox-collection__item.tox-collection__item--active .tox-collection__item-icon {
  color: var(--white);
}
.home-layout .tox .tox-textarea-wrap {
  border: none !important;
}
.dark-theme .home-layout .tox .tox-dialog__header,
.dark-theme .home-layout .tox .tox-dialog,
.dark-theme .home-layout .tox .tox-dialog__footer {
  background: var(--themeDarkTh);
}
.dark-theme .home-layout .tox .tox-dialog__title {
  color: var(--themeLightFi);
}
.dark-theme .home-layout .tox .tox-label,
.dark-theme .home-layout .tox .tox-toolbar-label {
  color: var(--themeLightFi);
}
.light-theme .home-layout .tox .tox-label,
.light-theme .home-layout .tox .tox-toolbar-label {
  color: var(--darkMore);
}
.dark-theme .home-layout .tox-dialog__header {
  border-bottom: 1px solid rgba(242, 237, 244, 0.07) !important;
}
.light-theme .home-layout .tox-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;
}
.dark-theme .home-layout .tox .tox-listboxfield .tox-listbox--select,
.dark-theme .home-layout .tox .tox-textarea,
.dark-theme .home-layout .tox .tox-textarea-wrap .tox-textarea:focus,
.dark-theme .home-layout .tox .tox-textfield,
.dark-theme .home-layout .tox .tox-toolbar-textfield {
  color: var(--themeLightFi);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--themeDarkFi);
}
.light-theme .home-layout .tox .tox-listboxfield .tox-listbox--select,
.light-theme .home-layout .tox .tox-textarea,
.light-theme .home-layout .tox .tox-textarea-wrap .tox-textarea:focus,
.light-theme .home-layout .tox .tox-textfield,
.light-theme .home-layout .tox .tox-toolbar-textfield {
  color: var(--darkMore);
  background-color: var(--white);
  border: 1px solid rgba(225, 225, 225, 1);
}
.dark-theme .home-layout .tox .tox-listboxfield .tox-listbox--select:focus,
.dark-theme .home-layout .tox .tox-textarea:focus,
.dark-theme .home-layout .tox .tox-textarea-wrap .tox-textarea:focus,
.dark-theme .home-layout .tox .tox-textfield:focus,
.dark-theme .home-layout .tox .tox-toolbar-textfield:focus {
  background: rgba(255, 255, 255, 0.1) !important;
  border-color: var(--themeDarkFi) !important;
}
.light-theme .home-layout .tox .tox-listboxfield .tox-listbox--select:focus,
.light-theme .home-layout .tox .tox-textarea:focus,
.light-theme .home-layout .tox .tox-textarea-wrap .tox-textarea:focus,
.light-theme .home-layout .tox .tox-textfield:focus,
.light-theme .home-layout .tox .tox-toolbar-textfield:focus {
  color: var(--darkMore);
  background-color: var(--white);
  border: 1px solid rgba(225, 225, 225, 1);
}
.light-theme .comList .comItems .comIInner .textEditorCommon .tox-editor-header,
.light-theme .comList .comItems .comIInner .textEditorCommon .tox-toolbar-overlord {
  background-color: #f4f2f9 !important;
}
.dark-theme .home-layout .tox .tox-dialog__body-nav-item {
  color: var(--themeLightFo);
}
.dark-theme .home-layout .tox .tox-collection__item-checkmark,
.dark-theme .home-layout .tox .tox-collection__item-icon {
  color: var(--themeLightFo);
}
.dark-theme .tox .tox-tbtn:hover svg path,
.dark-theme .tox .tox-tbtn--enabled svg path,
.dark-theme .tox .tox-tbtn--enabled:hover svg path,
.dark-theme .tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) svg path {
  fill: var(--white) !important;
}
.textEditorCommon .tox .tox-statusbar .tox-statusbar__help-text {
  opacity: 0;
  visibility: hidden;
}
.tox-tiered-menu .tox-menu .tox-collection__item-label p:after,
.tox-tiered-menu .tox-menu .tox-collection__item-label h1:after,
.tox-tiered-menu .tox-menu .tox-collection__item-label h2:after,
.tox-tiered-menu .tox-menu .tox-collection__item-label h3:after,
.tox-tiered-menu .tox-menu .tox-collection__item-label h4:after,
.tox-tiered-menu .tox-menu .tox-collection__item-label h5:after,
.tox-tiered-menu .tox-menu .tox-collection__item-label h6:after {
  font-weight: var(--fontWeight700);
  position: relative;
  margin-left: 10px;
  font-size: 70%;
  opacity: 0.6;
  top: -4%;
}
.tox-tiered-menu .tox-menu .tox-collection__item-label p:after {
  content: '15px';
}
.tox-tiered-menu .tox-menu .tox-collection__item-label h1:after {
  content: '30px';
}
.tox-tiered-menu .tox-menu .tox-collection__item-label h2:after {
  content: '23px';
}
.tox-tiered-menu .tox-menu .tox-collection__item-label h3:after {
  content: '18px';
}
.tox-tiered-menu .tox-menu .tox-collection__item-label h4:after {
  content: '15px';
}
.tox-tiered-menu .tox-menu .tox-collection__item-label h5:after {
  content: '13px';
}
.tox-tiered-menu .tox-menu .tox-collection__item-label h6:after {
  content: '10px';
}
.light-theme .tox .tox-tbtn:hover svg path,
.light-theme .tox-tbtn.tox-tbtn--enabled:hover svg path,
.light-theme .tox-tbtn.tox-tbtn--enabled svg path {
  fill: var(--white);
}
.light-theme .tox .tox-tbtn.tox-tbtn--disabled:hover svg path {
  fill: rgba(34, 47, 62, 0.5);
}
.light-theme .tox .tox-collection--list .tox-collection__item--enabled *,
.light-theme .tox .tox-collection--list .tox-collection__item--active p,
.light-theme .tox .tox-collection--list .tox-collection__item--active * {
  color: var(--white) !important;
}
.dark-theme .tox .tox-statusbar__help-text,
.dark-theme .tox .tox-statusbar__path-item,
.dark-theme .tox .tox-statusbar__wordcount {
  color: var(--themeLightFi) !important;
}
.dark-theme .tox-toolbar__primary,
.dark-theme .tox .tox-toolbar-overlord,
.dark-theme .tox .tox-editor-container .tox-editor-header,
.dark-theme .tox .tox-menubar {
  background-color: var(--themeDarkOn) !important;
}
.dark-theme .tox .tox-toolbar-overlord {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-theme .tox .tox-tbtn--bespoke .tox-tbtn__select-label,
.dark-theme .tox .tox-mbtn {
  color: var(--themeLightFi) !important;
  background: transparent !important;
}
.dark-theme .tox-tinymce {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-theme .tox .tox-menubar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
}
.dark-theme .tox .tox-editor-container .tox-sidebar-wrap {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .tox .tox-tbtn svg path {
  fill: var(--themeLightFi);
}
.dark-theme .tox .tox-tbtn--bespoke {
  background: rgba(255, 255, 255, 0.1);
}
.dark-theme .tox .tox-statusbar {
  background-color: var(--themeDarkOn) !important;
  border-top-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .tox.tox-tinymce-aux .tox-toolbar__overflow,
.dark-theme .tox .tox-menu {
  background-color: var(--themeDarkFi) !important;
}
.dark-theme .tox .tox-insert-table-picker>div,
.dark-theme .tox .tox-collection--list .tox-collection__group {
  border-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .tox .tox-collection__item-caret svg path,
.dark-theme .tox .tox-collection__item-checkmark svg path,
.dark-theme .tox .tox-collection__item-icon svg path {
  fill: var(--themeLightFi);
}
.dark-theme .tox .tox-collection__item-accessory,
.dark-theme .tox .tox-insert-table-picker__label,
.dark-theme .tox .tox-collection__item-label {
  color: var(--themeLightFo) !important;
}
.dark-theme .tox .tox-collection__item-label {
  color: var(--white) !important;
}
.dark-theme .tox .tox-collection--list .tox-collection__item--active .tox-collection__item-icon svg path {
  fill: var(--white);
}
.dark-theme .tox .tox-collection--list .tox-collection__item--active .tox-collection__item-accessory,
.dark-theme .tox .tox-collection--list .tox-collection__item--active .tox-collection__item-label {
  color: var(--white) !important;
}
.dark-theme .tox .tox-insert-table-picker__label {
  background: rgba(255, 255, 255, 0.1);
}
.dark-theme .tox-toolbar__primary .tox-toolbar__group .tox-tbtn,
.dark-theme .tox .tox-tbtn,
.dark-theme .tox .tox-insert-table-picker,
.light-theme .tox-toolbar__primary .tox-toolbar__group .tox-tbtn,
.light-theme .tox .tox-tbtn,
.light-theme .tox .tox-insert-table-picker {
  background: transparent;
}
.tox .tox-edit-area::before {
  border: 0 !important;
  box-shadow: rgba(140, 152, 164, 0.25) 0px 0px 1rem 0px;
}
.tox .tox-dialog__table thead th {
  font-size: 14px;
  font-family: var(--font-nunito);
  font-weight: var(--fontWeight600);
}
.tox .tox-dialog__table td {
  font-size: 14px;
  font-family: var(--font-nunito);
}
.tox .tox-dialog__body-content .tox-form__group h1 {
  font-size: 18px !important;
  font-weight: var(--fontWeight600) !important;
}
.tox .tox-dialog__body-content .tox-form__group p,
.tox .tox-dialog__body-content dd, 
.tox .tox-dialog__body-content dl, 
.tox .tox-dialog__body-content dt, 
.tox .tox-dialog__body-content ol, 
.tox .tox-dialog__body-content ul {
  font-size: 14px;
}
.tox .tox-dialog__body-content a {
  color: var(--primary) !important;
}
.dark-theme .tox .tox-dialog__body-content .tox-form__group p,
.dark-theme .tox .tox-dialog__body-content dd, 
.dark-theme .tox .tox-dialog__body-content dl, 
.dark-theme .tox .tox-dialog__body-content dt, 
.dark-theme .tox .tox-dialog__body-content ol, 
.dark-theme .tox .tox-dialog__body-content ul,
.dark-theme .tox .tox-dialog__table td {
  color: var(--themeLightFo);
}
.dark-theme .tox .tox-dialog__body-content .tox-form__group h1,
.dark-theme .tox .tox-dialog__table thead th {
  color: var(--themeLightFi) !important;
}
.dark-theme .tox .tox-dialog__table tbody tr {
  border-bottom-color: rgba(255, 255, 255, 0.06) !important;
}
.light-theme .tox .tox-dialog__body-content .tox-form__group p,
.light-theme .tox .tox-dialog__body-content dd, 
.light-theme .tox .tox-dialog__body-content dl, 
.light-theme .tox .tox-dialog__body-content dt, 
.light-theme .tox .tox-dialog__body-content ol, 
.light-theme .tox .tox-dialog__body-content ul,
.light-theme .tox .tox-dialog__table thead th,
.light-theme .tox .tox-dialog__table td {
  color: var(--theme);
}
.light-theme .tox .tox-menubar {
  border-bottom: 1px solid #f0f0f0 !important;
  margin-bottom: 1px;
}
.light-theme .tox .tox-dialog__table tbody tr {
  border-bottom-color: #f0f0f0 !important;
}
/* /tox */
/* Light theme */
.light-theme .clinicCalender .ant-picker-date-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view>.ant-picker-calendar-date>.ant-picker-calendar-date-value,
.light-theme .clinicCalender .ant-picker-date-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-disabled>.ant-picker-calendar-date>.ant-picker-calendar-date-value,
.light-theme h1,
.light-theme h2,
.light-theme h3,
.light-theme h4,
.light-theme h5,
.light-theme .ant-empty-description,
.light-theme .ant-tooltip .ant-tooltip-inner,
.light-theme small {
  color: var(--theme) !important;
}
.light-theme p {
  color: var(--theme);
}
.light-theme label {
  color: var(--darkMore);
}
.light-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.light-theme hr {
  background-color: rgba(0, 0, 0, 0.07);
}
.light-theme .ant-menu-submenu-popup .ant-menu-item-active,
.light-theme .ant-menu-submenu-popup .ant-menu-item-selected,
.light-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.light-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.light-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.light-theme .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.light-theme .ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu.ant-menu-submenu-open.ant-menu-submenu-active,
.light-theme .ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected,
.light-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:not(.ant-menu-item-selected):active {
  background-color: var(--whitesmoke) !important;
}
.light-theme .fullScreen,
.light-theme .ant-tooltip .ant-tooltip-inner,
.light-theme .ant-tooltip .ant-tooltip-arrow:before,
.light-theme .ant-table-filter-dropdown {
  background-color: var(--white) !important;
}
.light-theme .ant-picker-time-panel .ant-picker-content .ant-picker-time-panel-column .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgba(125, 75, 138, 0.1) !important;
}
.light-theme .ant-radio-wrapper .ant-radio .ant-radio-inner {
  border-color: #d9d9d9;
}
.light-theme .ant-select-selection-placeholder,
.light-theme ::-webkit-input-placeholder {
  color: var(--holderDark) !important;
  font-weight: var(--fontWeight500);
}
.light-theme .ant-select-selection-placeholder,
.light-theme :-ms-input-placeholder {
  color: var(--holderDark) !important;
  font-weight: var(--fontWeight500);
}
.light-theme .ant-select-selection-placeholder,
.light-theme ::placeholder {
  color: var(--holderDark) !important;
  font-weight: var(--fontWeight500);
}
.light-theme .oocdOutcomeCommon.viWiIcn {
  box-shadow: none;
  border-color: #d1d1d1;
  background-color: #f1f1f1;
}
/* Chat Placeholder */
.light-theme .ccLTInner input::-webkit-input-placeholder,
.light-theme .cSearch input::-webkit-input-placeholder {
  color: var(--muted) !important;
}
.light-theme .ccLTInner input:-ms-input-placeholder,
.light-theme .cSearch input:-ms-input-placeholder {
  color: var(--muted) !important;
}
.light-theme .ccLTInner input::placeholder,
.light-theme .cSearch input::placeholder {
  color: var(--muted) !important;
}
.cculILe>img {
  border: 2px solid transparent;
}
.light-theme .cculILe>img {
  border-color: var(--white);
}
/* /Chat Placeholder */
/* /Light theme */
/* Dark theme */
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5 {
  color: var(--themeLightFi);
}
.dark-theme .viWiIcn span.cursorPointer {
  color: var(--themeLightTh);
}
.dark-theme small {
  color: var(--themeLightFo);
}
.dark-theme .ant-select-selection-placeholder,
.dark-theme ::-webkit-input-placeholder {
  color: var(--holderDark) !important;
  font-weight: var(--fontWeight600);
}
.dark-theme .ant-select-selection-placeholder,
.dark-theme :-ms-input-placeholder {
  color: var(--holderDark) !important;
  font-weight: var(--fontWeight600);
}
.dark-theme .ant-select-selection-placeholder,
.dark-theme ::placeholder {
  color: var(--holderDark) !important;
  font-weight: var(--fontWeight600);
}
.dark-theme .comIInner .ant-select-selection-placeholder,
.dark-theme .comIInner ::-webkit-input-placeholder {
  color: var(--white) !important;
  opacity: 0.2;
}
.dark-theme .comIInner .ant-select-selection-placeholder,
.dark-theme .comIInner :-ms-input-placeholder {
  color: var(--white) !important;
  opacity: 0.2;
}
.dark-theme .comIInner .ant-select-selection-placeholder,
.dark-theme .comIInner ::placeholder {
  color: var(--white) !important;
  opacity: 0.2;
}
.dark-theme p,
.dark-theme label {
  color: var(--themeLightFi);
}
.dark-theme .ant-checkbox+span,
.dark-theme .ant-empty-description,
.dark-theme .ant-slider .ant-slider-mark-text,
.dark-theme .ant-slider .ant-slider-mark-text-active,
.dark-theme .ant-menu-submenu-popup .ant-menu-sub>li>.ant-menu-title-content,
.dark-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.dark-theme .ant-select-dropdown .ant-select-item .ant-select-item-option-content,
.dark-theme .ant-tabs-dropdown .ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item,
.dark-theme .ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-link,
.dark-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item>.ant-dropdown-menu-title-content,
.dark-theme .ant-menu-submenu-popup>.ant-menu>.ant-menu-submenu>.ant-menu-submenu-title>.ant-menu-title-content,
.dark-theme .ant-menu-submenu-popup>.ant-menu>.ant-menu-submenu>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.dark-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content span,
.dark-theme .ant-picker-content .ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: var(--themeLightFo) !important;
}
.dark-theme .ant-radio-wrapper .ant-radio .ant-radio-inner {
  border-color: transparent;
}
.dark-theme .ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.18) !important;
}
.dark-theme .ant-picker-time-panel .ant-picker-content .ant-picker-time-panel-column .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--primary) !important;
  color: var(--white) !important;
}
.dark-theme .thIcn>path,
.dark-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item>svg>path {
  fill: var(--themeLightFo) !important;
}
.dark-theme .ant-slider-disabled .ant-slider-rail {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.dark-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.dark-theme hr {
  background-color: rgba(242, 237, 244, 0.07);
}
.dark-theme .ant-tooltip .ant-tooltip-inner {
  background-color: var(--themeDarkFi);
  color: var(--themeLightSi) !important;
}
.dark-theme .ant-switch.ant-switch-checked {
  background: var(--primary) !important;
}
.dark-theme .ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-link:disabled {
  color: rgba(255, 255, 255, 0.25);
}
.dark-theme .ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
  border-top-color: var(--themeDarkTh);
}
.dark-theme .ant-switch {
  background-color: var(--muted) !important;
}
.dark-theme .ant-select-dropdown,
.dark-theme .ant-table-filter-dropdown,
.dark-theme .ant-dropdown .ant-dropdown-menu,
.dark-theme .ant-tooltip .ant-tooltip-arrow:before,
.dark-theme .ant-tabs-dropdown .ant-tabs-dropdown-menu,
.dark-theme .ant-menu-submenu-popup .ant-menu-sub {
  background-color: var(--themeDarkFo) !important;
}
.dark-theme .ant-menu-submenu-popup .ant-menu-item-selected,
.dark-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.dark-theme .ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu-open,
.dark-theme .ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu-active,
.dark-theme .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
.dark-theme .ant-menu-submenu-popup .ant-menu-item:not(.ant-menu-item-selected):active,
.dark-theme .ant-tabs-dropdown .ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item-active,
.dark-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.dark-theme .ant-tabs-dropdown .ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item-selected,
.dark-theme .ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled),
.dark-theme .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.dark-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:not(.ant-menu-item-selected):active,
.dark-theme .ant-tabs-dropdown .ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item:not(.ant-menu-item-selected):active,
.dark-theme .ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu.ant-menu-submenu-open.ant-menu-submenu-active,
.dark-theme .ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected,
.dark-theme .ant-menu-submenu-popup .ant-menu-item-active {
  background-color: var(--themeDarkTh) !important;
}
.dark-theme .ant-menu-submenu-popup>.ant-menu>.ant-menu-submenu>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  opacity: 0.3;
}
.dark-theme .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  color: var(--themeLightFo);
  border-color: rgba(255, 255, 255, 0.06);
}
.dark-theme .ant-picker-panel-container {
  background-color: var(--themeDarkFi) !important;
}
.dark-theme .ant-picker-panel .ant-picker-header {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.dark-theme .ant-picker-panel .ant-picker-header .ant-picker-header-view {
  color: var(--themeLightFo) !important;
}
.dark-theme .ant-picker-panel .ant-picker-header .ant-picker-header-next-btn,
.dark-theme .ant-picker-panel .ant-picker-header .ant-picker-header-super-next-btn,
.dark-theme .ant-picker-panel .ant-picker-header .ant-picker-header-super-prev-btn,
.dark-theme .ant-picker-panel .ant-picker-header .ant-picker-header-prev-btn {
  color: var(--themeLightFo);
  opacity: 0.65 !important;
}
.dark-theme .ant-picker-panel>.ant-picker-year-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner,
.dark-theme .ant-picker-panel>.ant-picker-month-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner,
.dark-theme .ant-picker-panel>.ant-picker-decade-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner,
.dark-theme .ant-picker-panel>.ant-picker-year-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell .ant-picker-cell-inner,
.dark-theme .ant-picker-panel>.ant-picker-month-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell .ant-picker-cell-inner,
.dark-theme .ant-picker-panel>.ant-picker-decade-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell .ant-picker-cell-inner,
.dark-theme .ant-picker-panel .ant-picker-body>.ant-picker-content>thead>tr>th,
.dark-theme .clinicCalender .ant-picker-body .ant-picker-content .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-disabled .ant-picker-cell-inner,
.dark-theme .ant-picker-panel .ant-picker-body>.ant-picker-content>tbody>tr>td.ant-picker-cell {
  color: var(--themeLightFo) !important;
}
.dark-theme .ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner,
.dark-theme .ant-picker-panel>.ant-picker-year-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner,
.dark-theme .ant-picker-panel>.ant-picker-month-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner,
.light-theme .clinicCalender .ant-picker-date-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view>.ant-picker-calendar-date>.ant-picker-calendar-date-value,
.light-theme .clinicCalender .ant-picker-date-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-disabled>.ant-picker-calendar-date>.ant-picker-calendar-date-value,
.dark-theme .ant-picker-panel>.ant-picker-decade-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner {
  opacity: 0.25 !important;
}
.light-theme .signAcco textarea {
  border-color: var(--holderLight) !important;
}
.light-theme .clinicCalender .ant-picker-date-panel .ant-picker-body .ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-today.ant-picker-cell-in-view>.ant-picker-calendar-date.ant-picker-calendar-date-today>.ant-picker-calendar-date-value,
.dark-theme .ant-picker-panel>.ant-picker-decade-panel>.ant-picker-body>.ant-picker-content>tbody>tr>td.ant-picker-cell.ant-picker-cell-in-view {
  opacity: 1 !important;
}
.dark-theme .ant-picker-panel>.ant-picker-footer {
  border-top-color: rgba(255, 255, 255, 0.06);
}
/* /Dark theme */
/* WCAG 2.1 AA */
noscript {
  background: #747578;
}
/* WCAG 2.1 AA */
.ssoLoader svg {
  width: 28px;
  height: 28px;
}
.dark-theme .ant-picker-dropdown .ant-picker-content td.ant-picker-cell {
  opacity: 0.45;
}
.dark-theme .ant-picker-dropdown .ant-picker-content td.ant-picker-cell.ant-picker-cell-disabled {
  opacity: 0.9;
}
.dark-theme .ant-picker-dropdown .ant-picker-content td.ant-picker-cell.ant-picker-cell-in-view {
  opacity: 1;
}
.uploadImgH .ant-upload.ant-upload-select .ant-upload img {
  height: auto;
  border-radius: 0 !important;
}
/* Responsive Menu */
@media (max-width: 992px) {
  /* onScoll */
  .onScoll header.headerCommon {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  .dark-theme .onScoll header.headerCommon {
    background-color: var(--themeDarkTh) !important;
  }
  .light-theme .onScoll header.headerCommon {
    background-color: var(--lightMore) !important;
  }
  .onScoll main.mainContent {
    padding-top: 60px;
  }

  /* /onScoll */
  /* respoMobile */
  .respoMobile .sidebarCommon .burgerMenu .burgerWrapper .burgerIcn {
    display: block;
    background: transparent;
  }
  .respoMobile .sidebarCommon .burgerMenu .burgerWrapper .burgerIcn:before,
  .respoMobile .sidebarCommon .burgerMenu .burgerWrapper .burgerIcn:after {
    transition:
      top 300ms 50ms ease,
      transform 300ms 350ms ease;
    top: 0;
  }
  .respoMobile .sidebarCommon .burgerMenu .burgerWrapper .burgerIcn:before {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .respoMobile .sidebarCommon .burgerMenu .burgerWrapper .burgerIcn:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  /* /respoMobile */
  .respoOverlap {
    width: 0;
    opacity: 0;
    height: 100%;
    background: var(--black);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
  }
  .respoMobile .respoOverlap {
    width: 100%;
    opacity: 0.45;
  }
  .mainContent {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dark-theme body .sidebarCommon {
    background-color: var(--themeDarkFi) !important;
  }
}
/* /Responsive Menu */
/* Define keyframes for the animation */
@keyframes slide-in {
  0% {
    /* transform: translateX(50%); */
    transform: scale(1);
    transform-origin: center;
  }

  50% {
    /* transform: translateX(50%); */
    transform: scale(1.02);
    transform-origin: center;
  }

  100% {
    /* transform: translateX(0); */
    transform: scale(1);
    transform-origin: center;
  }
}
/* Apply the animation to the element */
.editBy .ant-dropdown-trigger {
  display: inline;
}
.pbiReport iframe {
  border-radius: var(--bs-border-radius-lg) !important;
  border: 1px solid var(--white);
  background: var(--white);
  height: 680px !important;
  padding: 15px;
}
/* unreadComment */
.unreadComment {
  position: relative;
  display: inline-block;
}
.unreadComment:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--danger);
  border-style: solid;
  border-width: 1px;
  position: absolute;
  top: 2px;
  right: -2px;
  border-radius: 50rem;
}
.light-theme .unreadComment:after {
  border-color: var(--light);
}
.dark-theme .unreadComment:after {
  border-color: var(--theme);
}
/* /unreadComment */
/* upgratModal */
.upgratModal .ant-modal-body {
  padding: 0 !important;
}
.upgratModal .umIHead {
  position: relative;
  background: linear-gradient(180deg,
      rgba(253, 242, 253, 0) 0%,
      rgba(253, 238, 253, 0.65) 100%);
  height: 186px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.upgratModal .umIHead>.umIcn {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -80px;
}
.upgratModal .umIBody {
  padding: 40px;
}
.upgratModal .umIBody>h2,
.upgratModal .umIBody>h4 {
  color: var(--theme) !important;
}
.upgratModal .umIBody>h2 {
  line-height: 1.2;
}
.upgratModal .umIBody>h4 {
  line-height: 1.3;
}
/* /upgratModal */
.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after,
.ant-radio-wrapper:after,
.ant-checkbox-wrapper:after,
.ant-select.ant-select-show-arrow .ant-select-arrow .anticon-down>svg,
.ant-select.ant-select-show-arrow .ant-select-arrow .anticon-search>svg,
.ant-picker>.ant-picker-input>.ant-picker-clear>.anticon-close-circle>svg,
.ant-picker>.ant-picker-input>.ant-picker-suffix>.anticon.anticon-calendar>svg,
.ant-menu-submenu-popup .ant-menu-sub>.ant-menu-submenu>.ant-menu-submenu-title>svg,
.ant-select-selector:after,
html body .tox.tox-silver-sink .tox-notifications-container,
.tox-notification.tox-notification--in.tox-notification--warning,
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper>.ant-select-tree-title:after,
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode>.ant-select-tree-indent,
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode>.ant-select-tree-switcher.ant-select-tree-switcher-noop,
.ant-select-tree .ant-select-tree-list .ant-select-tree-list-holder-inner>.ant-select-tree-treenode>.ant-select-tree-switcher.ant-select-tree-switcher_open {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
.feedback_rate span {
  margin-right: 0px !important;
}
.feedback_rate {
  position: relative;
  font-size: small;
  bottom: 1px;
}
.feedback_rate>li.ant-rate-star {
  margin-inline-end: 0 !important;
}
.removeBr a>br,
.feedback_rate>li.ant-rate-star:not(:first-child) {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.feedback_rate>li.ant-rate-star .ant-rate-star-first>span,
.feedback_rate>li.ant-rate-star .ant-rate-star-second>span {
  margin: 0;
}
/* filters */
.addubListCommon .aubItem .fgFilters,
.filters .fgFilters {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .filters .filItems {
    flex: auto;
  }
}
/* /filters */
/* actionSwitch */
.actionSwitch .ant-switch {
  margin-left: 8px;
}
/* /actionSwitch */
/* email dark color css */
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent blockquote.gmail_quote {
  border-left-color: rgba(255, 255, 255, 0.07) !important;
}
.light-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent blockquote.gmail_quote {
  border-left-color: rgba(0, 0, 0, 0.07) !important;
}
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent div[dir='ltr'].gmail_signature font,
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent div[dir='ltr'].gmail_signature span,
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent span.gmail_signature_prefix {
  color: var(--themeLightFi);
  opacity: 0.7;
}
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent>div[dir='ltr'],
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent>div[dir='ltr']>span {
  color: var(--themeLightFi) !important;
}
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent .gmail_quote>div[dir='ltr'],
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent .gmail_quote>div[dir='ltr']>span {
  color: var(--themeLightFi) !important;
  opacity: 0.8;
}
.dark-theme .emBMi .embmBody .embmList .embmLItems.emExpand .embmICont .embmUContent blockquote.gmail_quote div[style='text-align: unset;'] * {
  color: var(--theme);
}
/* /email dark color css */
/* smallCheck */
.smallCheck .ant-checkbox-wrapper .ant-checkbox+span>span {
  padding-inline-start: 4px;
}
.smallCheck .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}
.smallCheck .ant-checkbox .ant-checkbox-inner:after {
  top: 44% !important;
  inset-inline-start: 22%;
}
.smallCheck label span:nth-child(2)>span {
  position: relative;
  top: -2px;
}
/* /smallCheck */
.rcBox {
  display: inline-flex;
  max-width: 26px;
  width: 100%;
  height: 26px;
  border-radius: 50rem;
  background-color: var(--primary);
  color: var(--white);
  justify-content: center;
  align-items: center;
}
.blockArea .ant-collapse-header,
.attributeArea .ant-collapse-header {
  padding: 8px 12px !important;
}
.blockArea .ant-collapse-header .ant-collapse-expand-icon,
.attributeArea .ant-collapse-header .ant-collapse-expand-icon {
  position: relative;
  right: -6px;
  padding-block-start: 2px !important;
}
.blockArea .ant-collapse-content-box,
.attributeArea .ant-collapse-content-box {
  padding: 12px !important;
}
.inTdlist ul,
.inTdlist ol {
  padding-left: 18px;
  list-style: revert-layer;
}
.spSCube {
  width: 50px;
  height: 26px;
  border-radius: 6px;
  border: 1px solid var(--holderLight);
  display: inline-block;
}
/* tOutBox */
.tOutBox .toInner {
  padding: 8px 12px;
  border-radius: 8px;
}
.tOutBox .toInner .toiLe {
  margin-right: 10px;
}
.tOutBox .toInner .toiRi * {
  line-height: 1;
}
.tOutBox .toInner .toiRi p {
  margin-top: 2px;
}
.tOutBox .toInner .toiRi .ant-statistic-content-value {
  font-size: 18px;
  letter-spacing: 1px;
}
.dark-theme .tOutBox .toInner {
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.02);
}
.dark-theme .tOutBox .toInner .toiLe svg path {
  fill: var(--themeLightFi);
}
.dark-theme .tOutBox .toInner .toiRi .ant-statistic-content-value {
  color: var(--themeLightFi);
}
.light-theme .tOutBox .toInner {
  border: 1px solid rgba(0, 0, 0, 0.02);
  background-color: var(--whitesmoke);
}
.light-theme .tOutBox .toInner .toiLe svg path {
  fill: var(--theme);
}
.light-theme .tOutBox .toInner .toiRi .ant-statistic-content-value {
  color: var(--theme);
}
/* /tOutBox */
.lineTitle {
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.lineTitle:before {
  content: '';
  width: 100px;
  display: block;
  position: absolute;
  left: 0;
  bottom: -0.5px;
}
.light-theme .lineTitle {
  border-bottom: 1px solid rgb(0 0 0 / 8%);
}
.light-theme .lineTitle:before {
  border-bottom: 1px solid var(--dark);
}
.dark-theme .lineTitle {
  border-bottom: 1px solid rgb(255 255 255 / 8%);
}
.dark-theme .lineTitle:before {
  border-bottom: 1px solid var(--white);
}
/* dragHere */
.dragHere:after {
  content: '';
  width: 100%;
  margin: 0 auto;
  height: 3px;
  border-radius: 6px;
  background: var(--info);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.dragHere span {
  padding: 4px 10px 4px 10px;
  border-radius: 10px;
  font-size: 10px;
}
.reCaptcha.mandatory div iframe {
  border: 2px solid var(--success);
  border-radius: 10px;
}
/* /shifPlan */
@media (min-width: 768px) {
  .customFormLink__tabs .ant-steps-item-icon {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
/* or */
.dark-theme .orCommon {
  color: var(--themeGray);
}
.light-theme .orCommon {
  color: #767676;
}
/* /or */
.susOffence.createdBy .lineHeightNormal.mb-1.uDropdown {
  margin-bottom: 0.05rem !important;
}
/* logoBranding */
.light-theme .logoBrandingCommon {
  background-color: rgb(0 0 0 / 6%);
}
.dark-theme .logoBrandingCommon {
  background-color: rgb(0 0 0 / 60%);
}
/* /logoBranding */
/* shimmer */
.wrapper {
  display: grid;
  place-content: center;
  height: 100dvh;
}
.post-card-skeleton {
  --skbg: hsl(227deg 13% 50% / 20%);
  display: grid;
  gap: 20px;
  width: max(100%, 250px);
  height: max(100%, 250px);
  background: #ffffff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.12);
}
.post-card-skeleton .post-thumbnail {
  display: flex;
  aspect-ratio: 2/1;
  width: 100%;
  height: 100%;
  background: var(--skbg);
}
.shimmer {
  position: relative;
  overflow: hidden;
}
.shimmer::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-90deg,
      transparent 8%,
      rgba(255, 255, 255, 0.28) 18%,
      transparent 33%);
  background-size: 200%;
  background-attachment: fixed;
  animation: placeHolderShimmer 2s ease-in-out infinite;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}
/* noMap */
.noMap {
  overflow: hidden;
  box-shadow: rgba(140, 152, 164, 0.06) 0px 0.375rem 0.75rem;
  border-radius: 10px;
}
.dark-theme .noMap {
  background-color: var(--themeDarkTh);
  border: 1px solid rgba(255, 255, 255, 0.05);
}
.light-theme .noMap {
  background-color: var(--themeLightFi);
  border: 1px solid var(--ghostLight);
}
.noMap:hover .noMapInner svg {
  animation: vrWiggle 2s linear infinite;
}
.noMapInner svg>path {
  fill: var(--themeLightTw);
}
/* /noMap */
/* change color dark to light */
.dark-theme .tox .tox-swatches__picker-btn svg path,
.dark-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover>svg>path,
.light-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover>svg>path,
.light-theme .uploadDocs .udInner svg>path {
  fill: var(--primary) !important;
}
.dark-theme .uploadDocs .udInner svg>path {
  fill: var(--primaryDark);
}
.light-theme .manRedDanDark .mandatory {
  color: var(--dangerDark) !important;
}
.light-theme .ccRHead .ccRHStatus .ccRHItems>span.ccRHText,
.light-theme .ccLHead .ccLHUStatus .ccLHUSItems>span.statusSuccess,
.light-theme .DLSuccess_sucLi {
  color: var(--success);
}
.light-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.light-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.light-theme .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:not(.ant-menu-item-selected):active,
.light-theme .DLPrimary_priLi,
.light-theme .DLPrimary_priLi .uDropdown .textPrimary,
.light-theme .activityValueCommon .avVVelue .file_name span,
.light-theme .uploadDocs .udInner>div .dark-theme .ant-menu-submenu-popup .ant-menu-item-active>.ant-menu-title-content,
.dark-theme .tox .tox-collection--list .tox-collection__item--active,
.dark-theme .ant-menu-submenu-popup .ant-menu-item-active:hover>.ant-menu-title-content,
.dark-theme .ant-menu-submenu-popup .ant-menu-sub>li.ant-menu-item-selected>.ant-menu-title-content,
.dark-theme .ant-menu-submenu-popup .ant-menu-item:not(.ant-menu-item-selected):active>.ant-menu-title-content,
.dark-theme .ant-menu-submenu-popup .ant-menu-item:not(.ant-menu-item-selected):active:hover>.ant-menu-title-content,
.dark-theme .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover .ant-dropdown-menu-title-content {
  color: var(--primary) !important;
}
.dark-theme .activityValueCommon .avVVelue a,
.dark-theme .activityValueCommon .avVVelue a span,
.dark-theme .activityValueCommon .avVVelue .file_name span,
.dark-theme .uploadDocs .udInner>div,
.dark-theme .susOffence a,
.dark-theme .cIOLDLItems h4.textPrimary .ant-dropdown-trigger>a,
.dark-theme .cIOLDLItems h4.textPrimary,
.dark-theme .embmBody .emTLSentby .ant-dropdown-trigger>a,
.dark-theme .insDBFour .indTLLe .bIItems .bIILink>a,
.dark-theme .DPrimaryLi,
.dark-theme .DLPrimary_priLi,
.dark-theme .DLPrimary_priLi .uDropdown .textPrimary {
  color: var(--primaryLight) !important;
}
.dark-theme .manRedDanDark .mandatory,
.dark-theme .bookableTimeSlots .tsITe label .mandatory {
  color: var(--red) !important;
}
.dark-theme .ccRHead .ccRHStatus .ccRHItems>span.ccRHText,
.dark-theme .ccLHead .ccLHUStatus .ccLHUSItems>span.statusSuccess,
.dark-theme .DLSuccess_sucLi {
  color: var(--successLight);
}
/* /change color dark to light */